<template>
  <Portlet
    title="Login Data"
    icon="lock"
    class="loginData"
  >
    <div class="fullLoginDataList">
      <LoadingPlaceholder v-if="loading" />
    
      <template v-else>
        <Grid
          :ref="kgm_ref"
          :style="{height: 'auto'}"
          :data-items="kgm_computedGridItems(fullLoginDataList)"
          :columns="kgm_responsiveColumns()"
          :filterable="true"
          :filter="kgm_filter"
          :pageable="kgm_pagable"
          :page-size="kgm_take"
          :skip="kgm_skip"
          :take="kgm_take"
          :total="kgm_allGridItems(fullLoginDataList)"
          :sortable="{
            allowUnsort: kgm_allowUnsort,
            mode: kgm_sortMode
          }"
          :sort="kgm_sort"
          selected-field="selected"
          @rowclick="kgm_gridOnRowClick"
          @filterchange="kgm_gridFilterChange"
          @pagechange="kgm_gridPageChange"
          @sortchange="kgm_gridSortChange"
        >
          <template
            slot="installationTemplate"
            slot-scope="{props}"
          >
            <td :class="props.className">
              <router-link
                :to="`/installation/${ props.dataItem.installationId }/software-components/${props.dataItem.componentId}`"
                class="alt-primary-color"
              >
                {{ kgm_getNestedValue(props.field, props.dataItem) }}
              </router-link>
            </td>
          </template>
          <div
            slot="installationFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="installationFilterInput"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a Installation"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('installationFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
          <div
            slot="typeFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <select
              ref="typeSelect"
              :key="`typeSelect-${props.value}`"
              :value="props.value"
              class="form-control mr-2 rounded-right alt-pointer"
              @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
              <option
                value=""
                disabled
              >
                {{ $t('pleaseSelectOne') }}
              </option>
              <option
                v-for="(typek, key) in typeList"
                :key="`typeListFilterOption-${key}`"
                :selected="kgm_selectDefaultOption(props, typek, 'typeSelect')"
              >
                {{ typek }}
              </option>
            </select>
            <div
              v-if="$refs != null && $refs.typeSelect != null && ($refs.typeSelect.value != null && $refs.typeSelect.value.length != 0)"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetSelect('typeSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
          <div
            slot="usernameFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="usernameFilterInput"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a Username"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('usernameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
          <div
            slot="componentTypeFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <select
              ref="componentTypeSelect"
              :key="`componentTypeSelect-${props.value}`"
              :value="props.value"
              class="form-control mr-2 rounded-right alt-pointer"
              @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
              <option
                value=""
                disabled
              >
                {{ $t('pleaseSelectOne') }}
              </option>
              <option
                v-for="(cTypek, key) in cTypeList"
                :key="`cTypekListFilterOption-${key}`"
                :selected="kgm_selectDefaultOption(props, cTypek, 'componentTypeSelect')"
              >
                {{ cTypek }}
              </option>
            </select>
            <div
              v-if="$refs != null && $refs.componentTypeSelect != null && ($refs.componentTypeSelect.value != null && $refs.componentTypeSelect.value.length != 0)"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetSelect('componentTypeSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
          <div
            slot="roleFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <select
              ref="roleSelect"
              :key="`roleSelect-${props.value}`"
              :value="props.value"
              class="form-control mr-2 rounded-right alt-pointer"
              @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
              <option
                value=""
                disabled
              >
                {{ $t('pleaseSelectOne') }}
              </option>
              <option
                v-for="(rolek, key) in roleList"
                :key="`roleListFilterOption-${key}`"
                :selected="kgm_selectDefaultOption(props, rolek, 'roleSelect')"
              >
                {{ rolek }}
              </option>
            </select>
            <div
              v-if="$refs != null && $refs.roleSelect != null && ($refs.roleSelect.value != null && $refs.roleSelect.value.length != 0)"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetSelect('roleSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
        </Grid>
      </template>
    </div>
  </Portlet>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: "FullLoginDataList",
  mixins: [
    kendoGridMixin
  ],
  data () {
    return {
      loading: true,
      fullLoginDataList: null,
       kgm_filter: {
        logic: "and",
        filters: [
          {
            field: "type",
            operator: "contains",
            value: ""
          },
          {
            field: "role",
            operator: "contains",
            value: ""
          }
        ]
      },
      kgm_take: 150,
      kgm_columns: [
        {
          field: 'installationName',
          filterable: true,
          filter: 'text',
          title: this.$t('installation'),
          filterCell: 'installationFilter',
          cell: 'installationTemplate'
        },
        {
          field: 'componentType',
          filterable: true,
          filter: 'text',
          title: 'Component Type',
          filterCell: 'componentTypeFilter',
        },
        {
          field: 'type',
          filterable: true,
          filter: 'text',
          title: 'User Type',
          filterCell: 'typeFilter',
        },
        {
          field: 'username',
          filterable: true,
          filter: 'text',
          title: 'Username',
          filterCell: 'usernameFilter',
        },
        {
          field: 'role',
          filterable: true,
          filter: 'text',
          title: 'Role',
          filterCell: 'roleFilter',
        }
      ]
    }
  },
  computed: {
    typeList () {
      if (!this.fullLoginDataList) {
        return [ "" ];
      }
      let typeList = Array.from(new Set(this.fullLoginDataList.map(o=>o.type)));
      typeList = typeList.filter(function (e) {return e});
      return typeList.sort();
    },
    roleList () {
      if (!this.fullLoginDataList) {
        return [ "" ];
      }
      let roleList = Array.from(new Set(this.fullLoginDataList.map(o=>o.role)));
      roleList = roleList.filter(function (e) {return e});
      return roleList.sort();
    },
    cTypeList () {
      if (!this.fullLoginDataList) {
        return [ "" ];
      }
      let cTypeList = Array.from(new Set(this.fullLoginDataList.map(o=>o.componentType)));
      cTypeList = cTypeList.filter(function (e) {return e});
      return cTypeList.sort();
    },
  },
  created () {
    this.getFullLoginDataList();
  },
  methods: {
    getDescription (val) {
      if(val) {
        return val.length > 100 ? val.substring(0, 100) + '...' : val
      }
      return null;
    },
    getFullLoginDataList () {
      this.loading = true;
      this.axios.get(`/SoftwareComponent/GetAllLoginData`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.fullLoginDataList = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    }     
  }
}
</script>
<style>
.fullLoginDataList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
}

</style>